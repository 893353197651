<template>
    <div class="container">

        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

        <div>
            <h3>Prospecção de clientes</h3> 
            
        </div>

        <div class="painel-superior">

            <div v-if="tOp == 'view'">
               <h4>Escolha o produto para ser prospectado</h4>
            </div>
            <div v-else>
               <button class="novo-btn" @click="salvarCliente">Salvar</button>
               <button class="novo-btn" @click="cancelarCliente">Cancelar</button> 
            </div>


        </div>

        <div id="list-table" v-if="tOp == 'view'">
           
            <div>
                <br /><br />
               
                <div id="head-table">
                    <div class="order-id"></div>
                    <div>Produto</div>
                   <div>Observação</div>
                    <div>Ações</div>  
                   
                </div>
               
               
                <div id="det-rows">
                    
                    
                    
                    <div class="det-row" v-for="produto in produtos" :key="produto.id">
                        <div class="order-number">{{produto.id}}</div>
                        <div>{{produto.descricao}}</div>
                        <div>{{produto.observacao}}</div>

                        <div >
                            <button class="alterar-btn" @click="cadastrarCliente(produto.id)">Cadastrar Cliente</button>
                           
                        </div>



                    </div> 
                    
                    <nav aria-label="...">
                        <ul class="pagination">
                            <li  @click="paginaAnterior()"  class="page-item"><a class="page-link" href="#" tabindex="-1">Anterior</a></li>
                            <li  v-for="pagina in totalPaginas" :key="pagina"    @click="calcOffSet(pagina)" class="page-item" :class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li  @click="proximaPagina()" class="page-item"><a class="page-link" href="#">Próximo</a></li>
                        </ul>
                    </nav>
                                  
                
                </div>

            </div>

        </div> 
        
        <div v-if="tOp != 'view'">
            <p> <strong>{{this.observacao_produto}}</strong> </p>


            <form>           
            
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="cnpj">CNPJ</label>
                        <input name="cnpj" v-model="cnpj" type="text" class="form-control" id="cnpj" placeholder="Digite somente números neste campos"  minlength="14" maxlength="14">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="nome">Razão Social</label>
                        <input name="nome" v-model="nome" type="text" class="form-control" id="nome" placeholder="Nome" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="telefones">Telefones</label>
                        <input name="telefones" v-model="telefones" type="text" class="form-control" id="telefones" placeholder="Digite os números de telefone" required>
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="celular">WhatsApp</label>
                        <input name="celular" v-model="celular" type="tel" class="form-control" id="celular" placeholder="Digite somente números neste campos com DDD" required>
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="email">E-mail</label>
                        <input name="email" v-model="email" type="email" class="form-control" id="email" placeholder="E-mail">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="contato">Contato</label>
                        <input name="contato" v-model="contato" type="text" class="form-control" id="contato" placeholder="Digite o contato" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="responsavel">Responsável</label>
                        <input name="responsavel" v-model="responsavel" type="text" class="form-control" id="responsavel" placeholder="Digite o responsável">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="celular1">WhatsApp Responsável</label>
                        <input name="celular1" v-model="celular1" type="tel" class="form-control" id="celular1" placeholder="Digite somente números neste campos com DDD">
                    </div>
                </div>




                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="obs_cliente">Observação</label>
                        <input name="obs_cliente" v-model="obs_cliente" type="text" class="form-control" id="obs_cliente" placeholder="Observação">
                    </div>
                </div>
            
            
            
            
                    

            </form>




        </div>

    </div>
</template>

<script>

import Message from '../../Message.vue'
import {urlAPI, tipoOp} from '../../../config.js'
import ProspeccaoCliente from '../Cadastro/ProspeccaoCliente.vue'
import {validarCNPJ, validarCelular} from "../../../funcoesDiversas.js"





export default {
    name:"ProspeccaoForm",
    Components:{
      Message,
      ProspeccaoCliente
    },
    data(){
        return{
            
            //Campos da grid  
            produtos:null,
            produto_id:null,
            
            //Campos salvar
            celular:"",
            celular1:"",
            nome:"",
            email:"",
            cnpj:"",
            obs_cliente:"",
            telefones:"",
            contato:"",
            responsavel:"",
            datacad:null,
            obs_prospeccao:"",
            valor_ofertado:0,
          

            
            //Campos informativos
            hashnumber:null,
            idusuario:null,
            idadmin:0,
            observacao_produto:null,
            
            //Variável de controle da página
            now:null,
            offset:0,
            limit:10,
            totalPaginas:0,
            paginaAtual:1,
            msg:null,            
            tOp:null,
            tipoAlerta:null,
            filtro:null,
            sort:null

        }
        
    },

    monted(){

    },

    created(){

    },

    methods:{
       
       
       isActive(pagina){
            return pagina == this.paginaAtual ? 'active' : ''
        },

        proximaPagina(){
            if (this.paginaAtual >= this.totalPaginas){
                return
            }
            this.paginaAtual ++
            this.calcOffSet(this.paginaAtual)
        },
        
        paginaAnterior(){

            if (this.paginaAtual <= 1){
               return 
            }


            this.paginaAtual --


            this.calcOffSet(this.paginaAtual)
        },

        calcOffSet(pagina){

            this.offset = (pagina * this.limit) - this.limit
            
            this.getProdutos()

            this.paginaAtual = pagina

            console.log('pagina ',pagina)
        },

        limparCampos(){
           
         
           this.celular = ""
           this.celular1 = ""
           this.nome = "" 
           this.email = ""
           this.cnpj = ""
           this.obs_cliente = ""
           this.obs_prospeccao = ""
           this.telefones = ""
           this.contato = ""
           this.responsavel = ""
           this.datacad = null

           
           this.hashnumber = ""
           this.idusuario = this.$store.getters.getUserID
           this.idadmin =  this.$store.getters.getIdAdmin
           
           
           

        },        
        
        
        limparMsg(){
           this.msg = null
           this.tipoAlerta = null 
        },


        cancelarCliente(){
            
            this.tOp = tipoOp.view
        },
        
        async salvarCliente(){

         
          if (!validarCNPJ(this.cnpj)){
              alert('CNPJ inválido')
              return 
          }

          if (!validarCelular(this.celular)){
              alert('WhatsApp Inválido, preencha o número com DDD. O númenro deve ter até 11 dígitos! ')
              return
          }

         
         
         let data = {
                
                idproduto:  this.produto_id,
                hashnumber:this.hashnumber,
                obs_cliente: this.obs_cliente,
                obs_prospeccao: this.obs_prospeccao,
                celular:  this.celular,
                celular1:this.celular1,
                nome: this.nome,
                email:this.email,
                cnpj:this.cnpj,
                telefones:this.telefones,
                contato:this.contato,
                responsavel:this.responsavel,
                datacad:this.datacad,
                
                valor_ofertado: this.valor_ofertado,
                idusuario: this.idusuario,
                idadmin: this.idadmin
           }

           let dataJson = JSON.stringify(data)

           let endpoint;
           let methods;
           
           if (this.tOp == tipoOp.create){
               endpoint = '/prospeccao/prodcli/insertprospeccao'
               methods = "POST"
               delete data.id //Para inclusão no banco é necessário excluir o id  
           }else if (this.tOp == tipoOp.update){
               endpoint = '/prospeccao/prodcli/updateprospeccao' 
               methods = "PUT"
               
           } 


           console.log(data)

            try {
                const req = await fetch(urlAPI+endpoint,{
                    method:methods,
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (res.Result == 'OK'){

                   this.tipoAlerta = "alert-success"
                   
                   this.msg = res.Message

                   // clear message
                   setTimeout(() => this.limparMsg(), 3000)

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message
                    
                    if(dataJson.indexOf("idx_email") > 0){
                        
                        this.tipoAlerta = "alert-warning"

                        this.msg = "Email já cadastrado"
                    }   
                    
                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)
                    


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)

               //this.tOp = tipoOp.view

               //this.getProdutos()

               this.limparCampos()
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 

                console.log('Error: ',error);

                // clear message
                setTimeout(() => this.limparMsg(), 3000)

           }

            
            
        },

        async getProdutos(){
            console.log(urlAPI)
            
            //Ordenação
            this.sort = 'descricao'
            
            //Filtrar por uma determinada informação 
            //this.filtro = "nomeusuario = 'master'"

            this.filtro = `idadmin = ${this.$store.getters.getIdAdmin}`

            console.log('ADMIN->',this.$store.getters.getIdAdmin)
            
            try {

                 const req = await fetch(urlAPI+`/prospeccao/produtos/list/offset/${this.offset}/limit/${this.limit}/filtro/${this.filtro}/sort/${this.sort}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                     
                })

                const res = await req.json()
                
                this.produtos = res.Prospeccao_Produtos

                //this.totalGeralRegistro = res.totalGeralRegistro

               
               this.produtos != null ?  this.totalPaginas = Math.ceil(res.totalGeralRegistro / this.limit) : 0

                
                console.log(this.produtos) 
               
                 
                
            } catch (error) {
                console.log("erro ao listar usuários", error)
            }


        },

        async cadastrarCliente(idProduto){
            try{
                
                
                const req = await fetch(urlAPI+`/prospeccao/produtos/find/${idProduto}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                  
                     
                })

                const res = await req.json()
              
                //Carrega dados
                this.produto_id = res.id
                this.observacao_produto = res.observacao

                this.limparCampos()
                
                this.tOp = tipoOp.create
                console.log('operação ',this.tOp) 
            }
            catch(error){
                console.log(error)
            }

            console.log(idProduto)
    
            
        },




    },

    mounted(){        
        this.tOp = tipoOp.view

        //this.getDadosPagina(1)
        
        this.getProdutos()
    }
}
</script>

<style scoped>
 
   #list-table{
       max-width: 1200px;
       margin: 0 auto;
   }
 

 
   #head-table, #det-rows, .det-row{
        display: flex;
       flex-wrap: wrap;
   }

   #head-table{
       font-weight: bold;
       padding: 12px;
       border-bottom: 3px solid #333;
   }

   .det-row{
       width: 100%;
       padding: 12px;
       border-bottom: 1px solid #ccc;
   }

   #head-table div, .det-row div{
       width: 25%
   }

   #head-table .order-id, #det-row .order-number{
       width: 5%;
   }


 .container{
      margin: 0 auto;
  }
   
   .order-id{
       margin: 0 auto;
   }

   .order-number{
       margin: 0 auto;
   } 



   .delete-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;
      transition: .5s;
   }

   .delete-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .alterar-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px;
      cursor: pointer;
      transition: .5s;
      
   }

   .alterar-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .novo-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px auto;
      cursor: pointer;
      transition: .5s;
      min-width: 90px;
      
   }

   .novo-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .painel-superior{
      
       float:right;
       
            
   } 

   .btn-custom{
       margin: 10 10 10 10;
   }


   .modal-active{
     display:block;
   }




</style>

